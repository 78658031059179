@charset "utf-8";

@font-face {
    font-family: 'didact_gothicregular';
    src: url('/assets/fonts/didactgothic-regular-webfont.woff2') format('woff2'),
         url('/assets/fonts/didactgothic-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'robotobold';
    src: url('/assets/fonts/roboto-bold-webfont.woff2') format('woff2'),
         url('/assets/fonts/roboto-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'robotoregular';
    src: url('/assets/fonts/roboto-regular-webfont.woff2') format('woff2'),
         url('/assets/fonts/roboto-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'robotoblack';
    src: url('/assets/fonts/roboto-black-webfont.woff2') format('woff2'),
         url('/assets/fonts/roboto-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

// Define defaults for each variable.

$base-font-family: 'robotoregular', sans-serif;
//-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Segoe UI Emoji", "Segoe UI Symbol", "Apple Color Emoji", Roboto, Helvetica, Arial, sans-serif !default;
$code-font-family: "Menlo", "Inconsolata", "Consolas", "Roboto Mono", "Ubuntu Mono", "Liberation Mono", "Courier New", monospace;
$base-font-size:   17px;
//16px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;

$table-text-align: left !default;

// Width of the content area
$content-width:    800px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;

$on-medium:        $on-palm !default;
$on-large:         $on-laptop !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
// Notice the following mixin uses max-width, in a deprecated, desktop-first
// approach, whereas media queries used elsewhere now use min-width.
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: #{$ratio}rem;
}

// Import pre-styling-overrides hook and style-partials.
@import
  "custom-variables", // Hook to override predefined variables.
  "base",             // Defines element resets.
  "layout",           // Defines structure and style based on CSS selectors.
  "custom-styles"     // Hook to override existing styles.
;
